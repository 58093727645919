import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useWindowResize } from 'beautiful-react-hooks'
import get from 'lodash/get'
import debounce from 'lodash/delay'
import styled from 'styled-components'

// Import Swiper styles
import 'swiper/swiper.scss'

const StyledSwiper = styled.div`
  .swiper-pagination {
    text-align: right;
    margin-top: 4px;
  }
  .swiper-pagination-current {
    margin-right: -4px;
  }
  .swiper-pagination-total {
    margin-left: -4px;
  }
  & + .project-info {
    margin-top: -16px;
    position: relative;
    z-index: 99;
  }

  .carousel-image {
    cursor: pointer;
  }
`
export default ({ images, onResize }) => {
  if (images && images.length) {
    SwiperCore.use([Pagination])

    useWindowResize((event) => {
      onResize()
    })

    return (
      <StyledSwiper className="carousel-wrapper">
        <Swiper
          spaceBetween={8}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          onClick={(swiper) => swiper.slideNext()}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            type: 'fraction',
          }}
          onResize={(swiper) => {
            debounce(() => {
              swiper.update()
            }, 1000)
          }}
        >
          {images.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="carousel-image">
                  <img src={get(image, 'url', '')} alt={get(image, 'alt', '')} />
                </div>
              </SwiperSlide>
            )
          })}
          <div className="swiper-pagination" />
        </Swiper>
      </StyledSwiper>
    )
  }

  return null
}
