import React, { useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import gsap from 'gsap'
import styled from 'styled-components'
import { addClass, hasClass, removeClass } from '../../utils/classie'
import ClientList from '../ClientList'
import { openLinksNewTab } from '../../utils/helpers'
import Grid from '../Grid'

const StyledPage = styled.div`
  opacity: 0;
`

const StyledSiteBackground = styled.div`
  position: fixed;
  background: url(${(props) => props.background}) center center no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`
const StyledColumn = styled.div`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  padding: 0 8px;
  display: block;
  width: 100vw;
  height: calc(calc(100vh - 38px) / 2);
  overflow: auto;
  position: relative;

  &.column-bio {
    font-family: 'Sneak Regular', 'Helvetica Neue', sans-serif;
    height: 38px;
    .client {
      padding-left: 0.5rem;
    }
    .project {
      padding-left: 1rem;
    }
    .column-header:before {
      content: '↓';
      margin-right: 4px;
    }
  }

  &.column-art .client {
    display: none;
  }

  .column-inner-content {
    padding-bottom: 16px;
  }

  .column-header {
    position: sticky;
    top: 0;
    z-index: 999;
    padding: 11px 0;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
    cursor: pointer;
    &:before {
      content: '↓';
      margin-right: 4px;
    }
  }

  &.column-bio .bio {
    margin-bottom: 8px;
  }

  .border-bottom {
    border-bottom: 1px solid ${(props) => props.color};
    padding: 8px 0;
    margin-bottom: 8px;
  }

  .border-top {
    border-top: 1px solid ${(props) => props.color};
    padding: 8px 0;
  }

  &.initial {
    .column-header:before {
      content: '↑';
    }
  }

  &.expanded {
    .column-header:before {
      content: '↑';
    }
  }
`

function FrontPageMobile({ pageData }) {
  const bio = pageData[0]
  let groups = []
  if (get(pageData, '[1].results', []).length > 0) {
    groups = groupBy(get(pageData, '[1].results', []), 'data.category')
  }

  const works = sortBy(get(groups, 'Work', []), 'uid')
  const arts = sortBy(get(groups, 'Art', []), 'uid')
  const siteSettings = get(pageData[2], 'results[0].data', {})

  const MIN_HEIGHT = 38

  useEffect(() => {
    openLinksNewTab()
  }, [])

  //---------------------------------------------------------------------------
  // When window resize
  //---------------------------------------------------------------------------

  const handleClickTitle = (columnType) => {
    const columnBio = document.querySelector('.column-bio')
    const columnWork = document.querySelector('.column-work')
    const columnArt = document.querySelector('.column-art')
    const windowHeight = window.innerHeight

    removeClass(columnWork, 'initial')
    removeClass(columnArt, 'initial')
    if (columnType === 'bio') {
      removeClass(columnWork, 'expanded')
      removeClass(columnArt, 'expanded')
      if (hasClass(columnBio, 'expanded')) {
        removeClass(columnBio, 'expanded')
        gsap.to([columnBio, columnWork, columnArt], 0.4, { height: MIN_HEIGHT })
      } else {
        addClass(columnBio, 'expanded')
        gsap.to([columnWork, columnArt, columnBio], 0.4, { height: MIN_HEIGHT })
        gsap.to(columnBio, 0.4, { height: windowHeight - 2 * MIN_HEIGHT })
      }
    } else if (columnType === 'art') {
      removeClass(columnBio, 'expanded')

      if (!hasClass(columnArt, 'expanded')) {
        addClass(columnArt, 'expanded')
        removeClass(columnWork, 'expanded')
        gsap.to([columnBio, columnWork], 0.4, { height: MIN_HEIGHT })
        gsap.to(columnArt, 0.4, { height: windowHeight - 2 * MIN_HEIGHT })
      } else if (
        !hasClass(columnWork, 'expanded') &&
        !hasClass(columnBio, 'expanded')
      ) {
        removeClass(columnArt, 'expanded')
        gsap.to([columnBio, columnWork, columnArt], 0.4, { height: MIN_HEIGHT })
      } else if (hasClass(columnArt, 'expanded')) {
        removeClass(columnArt, 'expanded')
        addClass(columnWork, 'expanded')
        gsap.to([columnBio, columnArt], 0.4, { height: MIN_HEIGHT })
        gsap.to(columnWork, 0.4, { height: windowHeight - 2 * MIN_HEIGHT })
      }
    } else if (columnType === 'work') {
      removeClass(columnBio, 'expanded')
      if (hasClass(columnWork, 'expanded')) {
        removeClass(columnWork, 'expanded')
        addClass(columnArt, 'expanded')
        gsap.to([columnBio, columnWork], 0.4, { height: MIN_HEIGHT })
        gsap.to(columnArt, 0.4, { height: windowHeight - 2 * MIN_HEIGHT })
      } else {
        addClass(columnWork, 'expanded')
        removeClass(columnArt, 'expanded')
        gsap.to([columnBio, columnArt], 0.4, { height: MIN_HEIGHT })
        gsap.to(columnWork, 0.4, { height: windowHeight - 2 * MIN_HEIGHT })
      }
    }
  }

  // Handling the link click on project
  const handleProjectClick = (column, wayfinderId) => {
    const elmt = document.getElementById(wayfinderId)
    const container = document.querySelector(`.${column}`)
    const columnBio = document.querySelector('.column-bio')
    const columnWork = document.querySelector('.column-work')
    const columnArt = document.querySelector('.column-art')
    const windowHeight = window.innerHeight

    if (column === 'column-art') {
      addClass(columnArt, 'expanded')
      gsap.to(container, { duration: 0, scrollTo: elmt })
      gsap.to([columnBio, columnWork], 0.4, {
        height: MIN_HEIGHT,
        onComplete: () => {
          removeClass(columnBio, 'expanded')
        },
      })
      gsap.to(columnArt, 0.4, {
        height: windowHeight - 2 * MIN_HEIGHT,
      })
    } else if (column === 'column-work') {
      addClass(columnWork, 'expanded')
      gsap.to(container, { duration: 0, scrollTo: elmt })
      gsap.to([columnBio, columnArt], 0.4, {
        height: MIN_HEIGHT,
        onComplete: () => {
          removeClass(columnBio, 'expanded')
        },
      })
      gsap.to(columnWork, 0.4, {
        height: windowHeight - 2 * MIN_HEIGHT,
      })
    }
  }

  const initColumnHeight = () => {
    const windowHeight = window.innerHeight
    const columnWork = document.querySelector('.column-work')
    const columnArt = document.querySelector('.column-art')

    gsap.set(columnWork, { height: (windowHeight - 38) / 2 })
    gsap.set(columnArt, { height: (windowHeight - 38) / 2 })
  }

  useEffect(() => {
    gsap.set('.home-page', { height: window.innerHeight })
    gsap.to('.page-container', 0.6, { opacity: 1, delay: 1 })
    initColumnHeight()
  }, [])

  return (
    <StyledPage className="page-container">
      {get(siteSettings, 'use_site_background_image', '') && (
        <StyledSiteBackground
          className="site-background"
          background={get(siteSettings, 'site_background_image.url', '')}
        />
      )}
      <StyledColumn
        className="column column-bio"
        background={siteSettings.about_background_color}
        color={siteSettings.about_text_color}
      >
        <span
          className="column-placeholder"
          onClick={() => handleClickTitle('bio')}
        />
        <p onClick={() => handleClickTitle('bio')} className="column-header">
          <span className="first">Rob</span>
          <span className="last"> Lewis</span>
        </p>
        <div className="column-inner-content">
          <div className="bio">{<RichText render={bio.data.bio} />}</div>
          <div className="clients border-top">
            <div className="client-work">
              <p>Work</p>
              <ClientList clients={works} handleProjectClick={handleProjectClick} />
            </div>
            <div className="client-art">
              <p>Art</p>
              <ClientList clients={arts} handleProjectClick={handleProjectClick} />
            </div>
          </div>
          <div className="clients border-top">
            {<RichText render={bio.data.select_clients} />}
          </div>
          <div className="award ">
            {<RichText render={get(bio, 'data.awards')} />}
          </div>
          <div className="credit">
            {<RichText render={get(bio, 'data.site_credit')} />}
          </div>
        </div>
      </StyledColumn>

      <StyledColumn
        className="column column-work initial"
        background={siteSettings.work_background_color}
        color={siteSettings.work_text_color}
      >
        <p onClick={() => handleClickTitle('work')} className="column-header">
          Work
        </p>
        <div className="column-inner-content">
          <Grid clients={works} playerTheme={'red'} />
        </div>
      </StyledColumn>
      <StyledColumn
        className="column column-art initial"
        background={siteSettings.art_background_color}
        color={siteSettings.art_text_color}
      >
        <p onClick={() => handleClickTitle('art')} className="column-header">
          Art
        </p>
        <div className="column-inner-content">
          <Grid clients={arts} playerTheme={'blue'} />
        </div>
      </StyledColumn>
    </StyledPage>
  )
}

export default FrontPageMobile
