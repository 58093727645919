import React from 'react'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
// import { hasClass, removeClass } from '../../utils/classie'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

function ClientList({ clients, handleProjectClick = () => {} }) {
  if (!clients) {
    return null
  }
  // const MAX_BIO = 300
  // const MIN_COLUMN = 62
  // const triggerResize = () => {
  //   window.dispatchEvent(new Event('resize'))
  // }

  // Handling the link click on project
  // const handleProjectClick = (column, wayfinderId) => {
  //   const elmt = document.getElementById(wayfinderId)
  //   const container = document.querySelector(`.${column}`)
  //   const columnBio = document.querySelector('.column-bio')
  //   const columnWork = document.querySelector('.column-work')
  //   const columnArt = document.querySelector('.column-art')
  //   const pageContainer = document.querySelector('.page-container')
  //
  //   const singleExpand = hasClass(columnBio, 'collapsed')
  //     ? Math.ceil((window.innerWidth - MIN_COLUMN) / 2)
  //     : Math.ceil((window.innerWidth - MAX_BIO) / 2)
  //
  //   if (hasClass(columnArt, 'collapsed') && hasClass(columnWork, 'collapsed')) {
  //     removeClass(pageContainer, 'expanded')
  //     removeClass(columnWork, 'collapsed')
  //     removeClass(columnArt, 'collapsed')
  //     gsap.to([columnWork, columnArt], 0.4, {
  //       width: singleExpand,
  //       onComplete: () => {
  //         triggerResize()
  //         gsap.to(container, { duration: 0, scrollTo: elmt })
  //       },
  //     })
  //     gsap.to('.column-inner-content', 0.5, { autoAlpha: 1, delay: 0.6 })
  //   } else if (
  //     hasClass(columnArt, 'collapsed') &&
  //     !hasClass(columnWork, 'collapsed') &&
  //     column === 'column-art'
  //   ) {
  //     removeClass(pageContainer, 'expanded')
  //     removeClass(columnArt, 'collapsed')
  //     gsap.to([columnArt, columnWork], 0.4, {
  //       width: singleExpand,
  //       onComplete: () => {
  //         triggerResize()
  //         gsap.to(container, { duration: 0, scrollTo: elmt })
  //       },
  //     })
  //     gsap.to('.column-inner-content', 0.5, { autoAlpha: 1, delay: 0.6 })
  //   } else if (
  //     !hasClass(columnArt, 'collapsed') &&
  //     hasClass(columnWork, 'collapsed') &&
  //     column === 'column-work'
  //   ) {
  //     removeClass(pageContainer, 'expanded')
  //     removeClass(columnWork, 'collapsed')
  //     gsap.to([columnArt, columnWork], 0.4, {
  //       width: singleExpand,
  //       onComplete: () => {
  //         triggerResize()
  //         gsap.to(container, { duration: 0, scrollTo: elmt })
  //       },
  //     })
  //     gsap.to('.column-inner-content', 0.5, { autoAlpha: 1, delay: 0.6 })
  //   } else {
  //     gsap.to(container, { duration: 0.6, scrollTo: elmt })
  //   }
  // }

  return (
    <div className="client-list-wrapper">
      {clients.map((client, clientIndex) => {
        const projects = sortBy(
          get(client, 'data.body', []),
          'primary.project_title[0].text',
        )
        const column = `column-${get(client, 'data.category', '').toLowerCase()}`
        return (
          <div key={`work-${clientIndex}`}>
            <div className="client">
              <div
                className="cursor-pointer"
                onClick={() =>
                  handleProjectClick(
                    column,
                    `${get(client, 'data.category', 'project')}-${clientIndex}-0`,
                  )
                }
              >
                {RichText.asText(client.data.group_name)}
              </div>
              {projects.length > 0 && (
                <ul className="projects">
                  {projects.map((p, projectIndex) => {
                    const wayfinderId = `${get(
                      client,
                      'data.category',
                      'project',
                    )}-${clientIndex}-${projectIndex}`
                    return (
                      <li
                        className={`cursor-pointer project ${wayfinderId}`}
                        key={`project-${clientIndex}-${projectIndex}`}
                        onClick={() => handleProjectClick(column, wayfinderId)}
                      >
                        {
                          <div>
                            {RichText.asText(get(p, 'primary.project_title'))}
                          </div>
                        }
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ClientList
